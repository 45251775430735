import Vue from 'vue'
import Router from 'vue-router'
import Store from '../store'
import Paso0 from '../components/views/Paso0'
import Paso1 from '../components/views/Paso1'
import Paso2 from '../components/views/Paso2'
import Paso3 from '../components/views/Paso3'
import Paso4 from '../components/views/Paso4'
import Pago from '../components/views/Pago'
import Final from '../components/Final'
import ErrorTbk from '../components/ErrorTbk'
// import RecuperarTicket from '../components/RecuperarTicket'

Vue.use(Router)

const publicPath = '/'

const router = new Router({
  mode: 'history',
  routes: [
    {
      'path': publicPath,
      'name': 'Paso0',
      'component': Paso0,
    },
    {
      'path': publicPath + 'paso1',
      'name': 'Paso1',
      'component': Paso1,
    },
    {
      'path': publicPath + 'paso2',
      'name': 'Paso2',
      'component': Paso2,
    },
    {
      'path': publicPath + 'paso3',
      'name': 'Paso3',
      'component': Paso3,
    },
    {
      'path': publicPath + 'paso4',
      'name': 'Paso4',
      'component': Paso4,
    },
    {
      'path': publicPath + 'final',
      'name': 'Final',
      'component': Final,
    },
    {
      'path': publicPath + 'error',
      'name': 'ErrorTbk',
      'component': ErrorTbk,
    },
    {
      'path': publicPath + 'pago',
      'name': 'Pago',
      'component': Pago,
    },
    /* {
      'path': publicPath + 'recuperar',
      'name': 'RecuperarTicket',
      'component': RecuperarTicket,
    }, */
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  if (!to.name) {
    next('/')
    return
  }
  if (to.name.startsWith('Paso')) {
    let p = parseInt(to.name.substring(4))
    if (typeof p === 'number') {
      if (p === 0 || p === Store.state.paso) {
        next()
        return
      }
    }
    next('/')
    return
  }
  next()
})

export default router
